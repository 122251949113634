import * as React from 'react';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlhref,
} from '../../../utils';
import * as Styled from './accountTemplateStyles';

const AccountTemplate = (props) => {
  const linkText = props?.textWithLink1?.text?.split(' ');
  return (
    <Styled.AccountContainer
      $backgroundImage={props?.backgroundImages[0]?.file?.url}
      $backgroundImageMediumDevice={props?.backgroundImages[1]?.file?.url}
      $backgroundImageSmallDevice={props?.backgroundImages[2]?.file?.url}
    >
        {isExternalUrl(props?.textWithLink1?.path) ? (
          <Styled.AnchorTag
            href={
              isExternalUrlhref(props?.textWithLink1?.path)
                ? props?.textWithLink1?.path
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + props?.textWithLink1?.path
                  )
            }
            target={isExternalUrl(props?.textWithLink1?.path) ? '_blank' : '_self'}

          >
          </Styled.AnchorTag>
          ) : (
            <Styled.AnchorTag
            href={
              isExternalUrlhref(props?.textWithLink1?.path)
                ? props?.textWithLink1?.path
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + props?.textWithLink1?.path
                  )
            }
            target={isExternalUrl(props?.textWithLink1?.path) ? '_blank' : '_self'}

            >
            </Styled.AnchorTag>
          )
        }
    </Styled.AccountContainer>
  );
};

export default AccountTemplate;
